import React from 'react';
import styled from 'styled-components';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Nav from 'components/Nav';
import SEO from 'components/SEO';

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.serif};
  text-align: center;
  margin-bottom: 30px;
  ${({ theme }) => theme.sizes.f2};
`;

export const TextWrapper = styled.div`
  margin: 80px auto;
  max-width: 700px;
`;

const P = styled.p`
  text-align: center;
  ${({ theme }) => theme.sizes.p};
`;

const NotFoundPage = ({ location }) => (
  <Layout>
    <Nav location={location} navTheme="dark" />
    <Container>
      <SEO title="404: Not found" />
      <TextWrapper>
        <Title>404: Not Found</Title>
        <P>Whoops, we may have moved this page.</P>
      </TextWrapper>
    </Container>
  </Layout>
);

export default NotFoundPage;
